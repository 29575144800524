<template>
  <router-view/>
</template>

<script>
import i18n from '@/i18n.js'
export default {
  computed: {
    locale () {
      return this.$i18n.locale
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to) {
        const calcTitle = i18n.t('meta_title_insurance')
        const calcDescription = i18n.t('meta_description_insurance')
        document.title = to.meta.title || calcTitle
        const meta = document.getElementsByTagName('meta')
        for (let i = 0; i < meta.length; i++) {
          if (meta[i].name.toLowerCase() === 'description') {
            meta[i].content = to.meta.description || calcDescription
          }
        }
      }
    },
    locale: {
      immediate: true,
      handler () {
        if (this.locale === 'ar') {
          document.body.classList.add('rtl')
          return
        }
        document.body.classList.remove('rtl')
      }
    }
  }
}
</script>
